.g-b-container {
    max-width: 1200px;
    margin: 38px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1em; /*dorothy*/
    margin: 0 /*dorothy*/;
}
.GalleryPost {
    width: 255px;
    /* 크롭 이미지 사이즈 변화에 따라 height 수정. asha 기존 447px */
    height: auto;
    cursor: pointer;
}
.GalleryPost .boardTitleBox {
    padding: 1em 0;
    text-align: center;
    opacity: .7;
}
.GalleryPost .GalleryPostTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width:100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }

.GalleryPost .GalleryPostSubtitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    color: #4F4F4F;

}

.ant-image{
    height:100%;
    width:100%;
    /* background-color: rgb(230,230,230); */
}
.GalleryPost .ant-image{
    width:100%;
    height: 100%;
    min-height:255px;
    /* background-color: rgb(230,230,230); */
    border-radius: 10px;
    outline: 0;
}

.galleryTextList .ant-image{
    width:210px;
    height:140px;
    background-color: rgb(230,230,230);
}


/* .skeletonImageList{
    width:210px;
    height:140px;
} */

/* .skeletonImage {
    width: 255px;
    height:382px;
    margin: -10px;
}

.skeletonImageBig {
    width: 382px;
    height: 255px;
    max-height: 255px;
} */

.GalleryPost img {
    display:block;
    width:255px;
    /* 크롭 이미지 비율 사이즈에 따라 height 수정. asha 기존 360px */
    height:382px;
    overflow: hidden;
    border-radius: 10px;
    outline: 0;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    transition: .6s;
    width: 100%; /*dorothy*/
}
.GalleryPost .boradImgBox {
    border-radius: 10px;
    overflow: hidden;
    position: relative; /*dorothy*/
}
.GalleryPost:hover .boradImgBox img{
    border: 5px solid #FFD560;
}
.GalleryPost .boradImgBox::after {
    content: '더보기 +';
    display: block;
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    height: 0;
    background: #FFD560;
    text-align: right;
    padding: .5em 1em;
    z-index: 2;
    color: #828282;
    font-size: .85em;
    transition: .6s;
    opacity: 0;
}
.GalleryPost:hover .boradImgBox::after {
    height: 28px;
    opacity: 1;
    border-radius: 0 0 10px 10px;
}
.GalleryPost:hover .boardTitleBox {opacity: 1; font-weight: bold;}

.g-b-container .ant-pagination{
    margin: 3em 0;
    width: 100%;
    text-align: center;
}
/* asha */
.galleryBig .GalleryPost{
    width: 100%;
    max-width: 382px;
    height: auto;
    /* max-height: 295px; */
    margin: 16px;
    cursor: pointer;
}
.galleryBig .GalleryPost img {
    display: block;
    width: 100%;
    max-width: 382px;
    height: auto;
    max-height: 255px;
    overflow: hidden;
    border-radius: 10px;
    outline: 0;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    transition: .6s;
}

.galleryBig .GalleryPost .boradImgBox::after {
    content: '';
    display: none;
    clear:both;
}
/* antd custom- pagination - page number */
.g-b-container .ant-pagination.mini .ant-pagination-item {font-size: 18px; font-weight: 700; opacity: .5; margin: 0 2px; padding: 4px; box-sizing: content-box;}
.g-b-container .ant-pagination.mini .ant-pagination-item-active,
.g-b-container .ant-pagination.mini .ant-pagination-item-active:focus,
.g-b-container .ant-pagination.mini .ant-pagination-item:hover {border-color: #ddd; opacity: 1;}
.g-b-container .ant-pagination.mini .ant-pagination-item-active a,.g-b-container  .ant-pagination-item:focus a,.g-b-container  .ant-pagination-item:hover a,/*page number color*/
/* pre/next button */
.g-b-container .ant-pagination-prev:focus .ant-pagination-item-link, .g-b-container .ant-pagination-next:focus .ant-pagination-item-link,
.g-b-container .ant-pagination-prev:hover .ant-pagination-item-link, .g-b-container .ant-pagination-next:hover .ant-pagination-item-link
{color: #828282;}
.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {margin: 0 4px;}

/* detail */
/* .g-b-table{ width: 100%; overflow: hidden;} */
.g-b-container .g-b-table table {border-spacing: 0; border-collapse:collapse;}
.g-b-container .g-b-table th, .g-b-container .g-b-table td {
    padding: 1em;
    border: 1px solid #dfdfdf;
    font-size: 1.2em;
    line-height:1.6em;
    white-space: pre-line;  /* 문화행사, 학술행사 페이지에서 \n에 대해 줄바꿈이 되도록 조치하기 위한 스타일 by Sarc 210623 */
}
.g-b-container .g-b-table {width:100%; line-height:1.7em; word-break:break-all; overflow:hidden; margin: 2em auto}
.g-b-container .g-b-table a {color:#000;text-decoration:underline}
.g-b-container .g-b-table img {max-width:100%;height:auto}
.g-b-container .g-b-table .lefttableimage {padding: 0; border: 0;}
.g-b-container .g-b-table .lefttableimage img {margin-right: 1em;}
.g-b-container .g-b-table table {margin: 0 auto;}
.g-b-container .g-b-table th {
    /* background-color: #F6F6F6; asha 수정  */
    background-color: #EFFAF3;
    width:100px;
    padding:1em 0;
}

/* table 구조 수정 - 로딩 이미지 셋팅 때문에 수정 */
.leftTableImage {
    padding-right: 1em;
    padding-bottom: 5px;
}
.rightTableContent{
    height:100%;
    width: 100%;
    min-height: 30vh;
    /* min-width:23vw; */
    padding-bottom: 5px;
}
.g-b-container .g-b-table table.tbl {
    height:100%;
    width: 100%;
}


/* .g-b-container .lefttableimage div {
    position: fixed;
    top: 0;
    width: 400px;
    max-width: 100vw;} */


    /* gallery-text-type-list Asha */
    .gallery-text-type-list{
        max-width: 1200px;
        margin: 0 auto;
    }
    .gallery-text-type-list .ant-list-items{
        text-align: left;
        padding-left:10px;
    }
    .gallery-text-type-list .ant-list-item{
        position:relative;
    }
    .gallery-text-type-list .ant-list-item-meta-content{
        position:relative;
        padding: 0 2em;
    }
    .gallery-text-type-list .ant-list-item-meta-title{
        position: absolute;
        left: 30px;
        top: 35%;
    }
    .gallery-text-type-list .ant-list-item-meta-title a{
        font-weight: 500;
        font-size:1.2rem;
    }

    .gallery-text-type-list .ant-list-item-meta-description{
        position: absolute;
        left: 30px;
        top: 55%;
    }
    .gallery-text-type-list .ant-list-item-meta {
        margin:0
    }

    .gallery-text-type-list .dateTime{
        position: absolute;
        right: 30px;
        top: 45%;
    }
    .gallerytext-imgWrap{
        width:70%;
        margin: 50px auto 25px;
    }


/* dorothy */
.g-b-container {
    justify-content: flex-start;
    padding: 0 1em;
    margin: 0;
    transform: skew(-0.1deg);
}
.GalleryPost {
    margin: 0;
    width: 100%;
}
.galleryBig .GalleryPost {
    margin: 16px auto;
}
.g-b-container > div {
    width: 25%;
    width: 255px;
    padding: 0 1%;
    min-width: 255px;
    box-sizing: content-box;
}
.g-b-container .ant-row {justify-content: flex-start;}
.leftTableImage .ant-imege { background:transparent;}
@media only screen and (max-width: 768px) {
    .g-b-container {padding: 0;}
    .board-main-wrap .detailMainInfoWrap .ant-col-24,
    .board-main-wrap .detailMainInfoWrap .ant-col-2 button {padding: 0!important;}
    .secondaryText {right: 0;}
    .g-b-container .g-b-table .lefttableimage {display: block; width: 100%!important; margin-bottom: 2em; padding: 0!important}
    .g-b-container .g-b-table th {display: block; width: 30%; float: left; min-width: 88px;}
    .g-b-container .g-b-table td {display: block; width: 70%; float: left; min-width: 200px;}
    .g-b-container .g-b-table tr th, .g-b-container .g-b-table tr td{ width: 100%;}

    .galleryBig .GalleryPost {margin: 0 auto 2.5em auto;}
    /* list type */
    .gallery-text-type-list .dateTime {display: none;}
    .gallery-text-type-list .ant-list-item-meta-title,
    .gallery-text-type-list .ant-list-item-meta-description{ position: relative; left: 0;}
    .gallery-text-type-list .ant-list-item-meta-content {height: auto; padding: 0;}
    .gallery-text-type-list .ant-list-item {padding: 1em 0;}
    /* detail page */
    .board-main-wrap .detailMainInfoWrap .ant-col-24 {margin: 0!important;}
    .g-b-table .leftTableImage {
        min-width: 100%;
        padding: 0;
    }
    .g-b-table .leftTableImage .ant-image {background: transparent; margin-bottom: 1em;}
    .g-b-table .rightTableContent {
        min-width: 100%;
    }
    .g-b-container .g-b-table table.tbl { height: auto;}
}



/* 문화유산, 인물 카테고리 */

.galleryTextList{
    padding: 0 35px;
}

.depth-row{
    margin-bottom: 50px;
}

.depth-select{
    width: 220px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.depth-select .ant-select-selector{
    border: 1px solid #E0E0E0 !important;
}

@media only screen and (max-width: 768px) {
    .galleryTextList{
        padding: 0 15px;
    }

    .depth-row{
        margin-bottom: 50px;
    }

    .depth-select{
        width: 220px;
        margin-right: 15px;
    }

}


@media only screen and (max-width: 1086px) {
    .g-b-container {justify-content: center;}
}
@media only screen and (max-width: 480px) {
    /* list type */
    .gallery-text-type-list .ant-list-item-meta-content {width: 100%; min-width: 80vw}
    .gallery-text-type-list .ant-list-item-meta {flex-direction: column;}
    .gallery-text-type-list .ant-list-item-meta-avatar { margin: 0 auto 1em;}
    .gallery-text-type-list  .ant-list-item-meta-description { width: 100%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap }
    /* detail page */
    .detailMainInfoWrap, .board-main-wrap .ant-layout-content .ant-row > .ant-row, .detailFooterWrap, .ql-editor {padding: 0!important;}
    .detailFooterWrap { margin: 2em 0; }
    .detailFooterWrap .detailIconWrap { right: 10px; }
    .ant-col .prevnextList .ant-list-item-action { width: 100%;}
    .ant-col .prevnextList .ant-list-item li:first-child{ width: 30%;}
    .ant-col .prevnextList .ant-list-item li:last-child{ width: 70%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; text-align: left; padding-left: 1em; margin: 0 0 -6px 0;}
    .board-main-wrap .detailMainInfoWrap .ant-col-2 button {display: none;}
    /* list page */
    .GalleryPost .ant-image {min-height: auto;}
}
