.ant-col > div.poster {
    position: relative;
    margin-bottom: 100px;
}

button{
    height:100%;
}
button:focus {
    outline:none !important;
}

.poster {
    background: #fff;
    max-height: 100%;
    margin: 0 auto;
    padding: 0;
}

.arrowbtWrap {
    float: right;
}

.arrowBt {
    color: #828282;
    background-color: transparent;
    border:none;
    font-size:1.5rem;
    margin:auto;
    font-family: sans-serif;
}
.arrowbtWrap span {
    font-size: 18px!important;
}

@media only screen and (max-width: 768px){
    .ant-col > div.poster {
        margin-bottom: 1em;
    }
}