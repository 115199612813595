/* Layout */
.boardLayout {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - 350px);
}

/* boardContent */
.boardContent {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

/* Board Header*/
.ant-layout-header {
/* position: fixed; */
background-color: #ffffff;
height: 110px;
width: 100%;
margin: 0 auto;
padding: 0;

}
.bw-titleWrap{
  height:110px;
  background-image: url("../img/titleBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bw-title{
  color:#000000;
  font-weight: 700; /*dorothy*/
  font-size:2rem;
  vertical-align: middle;
}


/*  sider */
.ant-layout-sider {
  background-color: #ffffff;
  /* margin-left: 30px; */
}
.siderWrap {
  height:100%;
  width: 100%;
  background-color:#ffffff;
  margin: 0 auto;
  padding: 0;
}

.siderWrap a {
  cursor: pointer;
  font-size: 15px;
}

.siderWrap li a:hover {
  color: #222222;
}

.siderWrap ul {
  padding:0;
  margin: 0 auto;
}
.siderWrap li{
  color: #222222;
  font-size: 0.9rem;
}

.first-side-menu{
  text-align: left;
  width: 100%;
}

.first-side-title{
  text-align: left;
  display: inline-block;
  width: 100%;
  background-color: #EFFAF3;
  height:53px;
  line-height:53px;
  padding-left: 20px;
  border-radius: 4px; /*dorothy*/
}

.first-side-title span {
  font-weight: bold;
  font-size: 18px;
}

.second-side-menu{
  text-align: left;
  padding-left: 20px !important;
}

.second-wrap-div:first-child {
  margin-top: 20px;
}

.second-side-title{
  text-align: left;
  display: inline-block;
  width: 90%;
  margin:0 auto 10px auto;
  border-bottom: 1px solid #C4C4C4;
  padding-bottom: 7.5px;
}


.third-side-title{
  text-align: left;
  display: inline-block;
  width: 100%;
  line-height:30px;
}

.third-side-title:last-child {
  margin-bottom: 20px;
}

.third-side-title a{
  font-size: 0.9rem !important;
  color: #828282;
  font-weight: 400;
}
/* Board Content */
.ant-layout-content{
  padding: 0;
}

/* Form */
.formInput{
    width: 100%;
}

/* Editor */
.quill-blur {
  display: none;
}

/* List */
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  background:transparent;
  border-bottom: 1px solid #FFD560;
  /* -webkit-transition: background 0.3s ease; */
  /* transition: background 0.3s ease; */
}

.board-main-wrap .ant-table-thead .ant-table-cell:nth-child(2){
  text-align: left;
}

/* Board Basic */

.ql-editor {
  max-height: 100%;
  height: 100%;
}

.quill {
width: 100%;
min-height:200px;
padding-top: 25px;
}

.quill img {
  max-width: 100%;
}

.quill-blur {
  display: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height:40px;
}
.ant-select {
  line-height: 40px;
  margin-right: -1px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 40px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus,
.ant-input:focus, .ant-input-focused,
.ant-input:hover, .ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused
 {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}
.ant-upload-list {
  float: left;
}
.ant-form-item-label > label {
  font-size: 16px;
  font-weight:600;
}

.writeWrap {
  padding: 70px 0;
}

.writeForm{
  width: 100%;
  max-width:1100px;
  margin: 0 auto;
  padding:0 24px 24px;
}

.ant-form{
  width: 100%;
}

.ant-input,
.ant-picker {
  height:40px;
  float:left;
}

/* 이미지 박스 */
.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card {
  width: 235px;
  height: 235px;
  display: inline-block;
  margin: 0;
  vertical-align: top;
}

.mainInfo{
  padding: 40px;
  /* #FFD560 */
  border: 1px solid rgb(197, 197, 197);
  border-radius:12px;
  margin-bottom: 40px;
}

.writeTitle {
  width: 100%;
  height: 110px;
  font-size:2rem;
}

.shortInput{
  /* width:220px; */
  width: 100%;
}

.mediumInput {
  width:340px;
  max-width: 100%;
}
.thumbCol .ant-form-item{
  margin-bottom: 18px;
}


.cateCol {
  padding-left: 2.5%;
}
.dateCol{
  padding-left: 3%;
}

/* List View */
.ant-table {font-size: 16px;}
.ant-table table {
  margin: 30px auto;
}

.ant-table-thead .ant-table-cell {
  background: transparent;
  padding-left:20px;
  text-align: center;
  /* color: transparent; */
  /* color:#828282; */
}

.ant-table-thead,
.ant-table-thead > tr > th {
  /* background:rgba(51, 68, 81,0.5);
  color:#ffffff; */
  border-bottom: 1px solid #FFD560;
  /* padding: 20px; */
  line-height: 1;
}
.ant-table-tbody{
  margin-top: 20px;
}

.grayTxt{
  color:#828282;
}

.ant-row {
  width: 100%;

}

/* detail View */
.detailMainInfoWrap{
  width: 100%;
  margin: 0 auto;
  padding: 24px 24px 0;
  position:relative;
}

.detailFooterWrap{
  width: 100%;
  padding: 0 24px;
  position:relative;
}


.ant-btn.editBt{
  color: cornflowerblue;
  font-weight: bold;
}
.titleText{
  text-align:left;
  padding: 15px 0;
}
.secondaryText{
  position: absolute;
  right: 35px;
  bottom:28px;
  display: inline-block;
}
.ant-divider.titleDivider{
  border-top: 1px solid #E0E0E0;
}

.ant-divider-horizontal{
  margin-top:0;
}
.detailIconWrap{
  position: absolute;
  right:35px;
  top:5px;
  display: inline-block;
}

.prevnextList a{
  color:#666666;
}

.inputBxStyle {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 220px;
  height:40px;
  min-width: 0;
  padding: 4px 11px;
  line-height: 1.8;
  text-align:center;
}

.inputBxStyle span{
  vertical-align:middle;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  list-style: none;
}

.dateCol .ant-input,
.dateCol .ant-picker  {
  width: 100%;
}

/* program detail */
.detail-program-MainInfoWrap{
width: 100%;
margin: 0 auto;
max-width: 1000px;
text-align: left;
}
.detail-program-MainInfoWrap button {
  padding: 0;  /*dorothy*/
}
.programDetailInfoWrap{
width: 100%;
margin: 35px auto;
padding: 25px;
border: 1px solid #FFD560;
max-width: 1000px;
overflow: hidden;
margin-left: auto;
margin-right:auto
}
.programDetailInfoWrap .ant-space-item { /*dorothy*/
  display: table;
  height: 320px;
}
.programDetailInfoWrap .ant-image { /*dorothy*/
  display: table-cell;
  vertical-align: middle;
}
.info-first-col{
width: 100%;
max-width: 320px;
max-height: 320px;
padding-top: 2px;
overflow: hidden;
}

.info-second-col{
text-align: left;
padding-left: 7%;
padding-top:5px;
}

.info-second-col h3,
.info-second-col ul li {
text-align: left;
}

.info-second-col h1.ant-typography,
.info-second-col .ant-typography h1 {
margin-bottom: 0.4em;
}

.userLimitTxt {
color:#828282;
font-size: 0.8rem;
height: 18px;
line-height: 18px;
display: inline-block;
height:100%;
vertical-align: unset;
}
.userLimitTag{
color: white;
display: inline-block;
height: 100%;
background-color: #FFD560;
font-size: 0.8rem;
padding: 0 7px;
border-radius: 12px;
  vertical-align: unset;
}
.listCol{
padding-top: 20px;
}

.listCol,
.listCol ul{
padding: 16px 16px 0 0;
height: 100%;
margin: 0;
}
.listCol li {
text-align: left;
height: 50px;
font-size: 14px;
padding-top: 16px;
border-bottom: 1px solid #e3e3e3;
vertical-align: bottom;
width: 100%;
overflow: hidden;
font-weight: 600;
}
.listCol li span{
display: inline-block;
vertical-align: bottom;
}
.info-second-col .listTitle{
width: 30%;
min-width: 60px; /*dorothy*/
font-weight: 500; /*dorothy*/
}

.qulii-body-row{
width: 100%;
border-top: 1px solid #FFD560;
margin: 0 auto 35px;
padding: 2%;
max-width: 1000px;
overflow: hidden;
margin-left: auto;
margin-right:auto;
}

.bottomInquiry {
width: 100%;
max-width:1000px;
height:60px;
margin:30px auto 0;
text-align:left;
border-bottom: 1px solid #FFD560;
position: relative;
}
.bottomInquiry span,
.bottomInquiry h4 {
vertical-align: bottom;
}


.bottomInquiry button {
border-color: #FFD560;
width:170px;
height:35px;
position:absolute;
right: 0;
bottom:20px;
}

.bottomInquiry button:hover,
.bottomInquiry button:active,
.bottomInquiry button:focus
{
background-color: #FFD560;
border-color: #FFD560;
color: #000000;
}

.btnApply {
background-color: #FFD560;
border-color: #FFD560;
width: 170px;
height: 35px;
color: #000000;
}

.btnApply:hover,
.btnApply:active,
.btnApply:focus {
background-color: #FFD560;
border-color: #FFD560;
color: #000000;
}
/* 강좌신청 모달팝업 */
.applyModal{
 width:45%;
 max-width: 600px;
 min-width: 300px;
}
.applyModal .ant-modal-content{
padding:20px;
text-align:center;
}

.applyModalBtn{
width: 41%;   /*210701 Sarc*/
max-width: 170px;
height: 35px;
border-color: #FFD560;
font-weight: 400;
color: #000000;
}

.applyModalBtn:hover,
.applyModalBtn:active,
.applyModalBtn:focus {
border-color: #FFD560;
color: #000000;
}

.reloadSearchBtn{ /* 회원가입전 유저조회 처리용 임시 스타일 적용 by Sarc 210630 */
  position:absolute;
  right:0;
  width: 35px;
  height: 35px;
  border-color: #FFD560;
  font-weight: 400;
  color: #000000;
}
.reloadSearchBtn:hover,
.reloadSearchBtn:active,
.reloadSearchBtn:focus {
  border-color: #FFD560;
  color: #000000;
}

.ant-modal-close{
display:none
}

.ant-result-error{
padding:20px 0;
}
.ant-result-title{
font-weight: 600;
}
.ant-result-icon{
margin-bottom:40px
}
.ant-result-subtitle{
font-size:14px;
font-weight: 600;
color:#828282;
line-height:20px;
}

.ant-result-extra{
margin-top: 70px;
}
.ql-tooltip,
.ql-hidden{
  display:none !important;
}

.detail-title{
  position: fixed;
  top:120px;
  width:100vw;
}

/* quill editor에서 삽입될 비디오 사이즈 조절. client쪽과도 동일하게 맞춰야 함. by Sarc */
.ql-editor .ql-video {
  width:720px;
  height:405px;
}
/* asha */
.ant-table-pagination.ant-pagination{
  display: inline-block;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  border: none;
}
.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:focus,
.ant-pagination .ant-pagination-item:hover {
  border-color: transparent;
  border: none;
  opacity: 1;
}
.ant-pagination-item{
  border: none
}
/* antd custom- pagination - page number - dorothy.ver copy by asha */
.ant-pagination{
  margin: 3em 0;
  width: 100%;
  text-align: center;
}
.ant-pagination.mini .ant-pagination-item {font-size: 18px; font-weight: 700; opacity: .5; margin: 0 2px; padding: 4px; box-sizing: content-box;}
.ant-pagination.mini .ant-pagination-item-active,
.ant-pagination.mini .ant-pagination-item-active:focus,
.ant-pagination.mini .ant-pagination-item:hover {border-color: #ddd; opacity: 1;}
.ant-pagination.mini .ant-pagination-item-active a, .ant-pagination-item:focus a, .ant-pagination-item:hover a,/*page number color*/
  /* pre/next button */
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link
{color: #828282;}
.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {margin: 0 4px;}

/* 업로드쪽용 스타일 임시 by Sarc */
.ant-upload-list-text-container {cursor:pointer}
.ant-upload-list-text-container .ant-upload-list-item-name {text-align:left}
.ant-upload-list-text-container span {color:#0645AD}

/* dorothy */
.board-main-wrap .ant-row { justify-content: flex-start;}
.ant-col .prevnextList .ant-list-item-action { margin: 0; }

/* Asha */

.textAreaView.ant-input[disabled]{
  height:100%;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 25px;
  border: none;
  overflow-y: auto;
  cursor: auto;             /*비활성 상태일때 빨간사선원 마우스 커서 뜨는거 처리 by Sarc 210507*/
}
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  display:block;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
    background:rgb(218, 218, 218);
}



@media screen and (min-width: 0) and (max-width: 830px) {
  .cateCol{
    padding-left: 7%;
  }
  .edit-focus .quill {
    position: absolute;
    top: 0;
    height: 226px;
    overflow: hidden;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .quill-blur {
    display: block;
    position: absolute;
    top: 226px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .ql-editor {
    height: 160px;
    border-bottom: 1px solid #ccc;
  }
  .edit-focus .navbar {
    display: none;
  }
  .edit-focus .main-panel {
    padding: 0 !important;
  }
  .edit-focus .main-panel .main-content {
    min-height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .info-first-col {margin: 1em auto;} /*dorothy*/
  .newTag{ opacity:0 !important }
}

@media screen and (max-width: 1200px) {
  .cateCol{
    width: 100%;
    padding-left:5%;
  }
  .cateCol .ant-input,
  .cateCol .ant-select{ width: 100% !important;}

  .dateCol{
    width: 100%;
    padding-left: 0;
  }
  .dateCol .ant-input,
  .dateCol .ant-picker  {
    width: 100%;
  }
}
@media screen and (min-width: 1300px) {
  .dateCol{
    padding-left: 3%;
  }
}
@media screen and (max-width: 768px) {
  h1.ant-typography, .ant-typography h1 {font-size: 2em;}
  .board-main-wrap .quill img {display: block; max-width: 80vw; margin: 0 auto;}
  /* basic board list */
  .List {margin: 0!important; padding: 0!important;}
  .List .ant-table-tbody > tr > td, .List .ant-table-thead > tr > th {padding: 1em .5em;}
}
@media screen and (max-width: 599px) {
  .board-main-wrap .ant-table {font-size: 14px;}
  .listData {width: 100%;}
  .listCol li {height: 62px;}
  .listCol, .listCol ul { padding: 24px 16px 16px 0;}
  /* inquriy btn*/
  .bottomInquiry {height: 112px;}
  .bottomInquiry button {/*dorothy*/ left: 0; width: 100%; }
  /* basic board - notice */
  .List, .List .ant-table-thead {padding: 0!important;}
  .List .ant-table-container .writerTxt {text-indent: -999px;}
  .detailMainInfoWrap, .detailMainInfoWrap + .ant-row-middle, .detailFooterWrap {padding: 0!important;}
  .List .ant-table-tbody > tr > td, .List .ant-table-thead > tr > th {padding: 1em 0;}
  .bottomInquiry button {position: relative; bottom: -20px; min-width: fit-content; display: block;}
}

.ant-btn-text:hover, .ant-btn-text:focus, .ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {background: transparent;}

/* add by Sarc - 정확한 사유는 모르지만, ie에서는 하단 클래스들에 대해 width가 비정상적으로 넓게 책정되어 max-width로 제한. ie10+에서만 적용된다는 css 적용 */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  /* IE10+ CSS styles goes underneath */
  .ant-space, .ant-space-item {max-width:100%;}
}

/* 회원가입전 유저조회 처리용 임시 스타일 적용 by Sarc 210630 */
.userExistCheck .ant-modal {top:33vh}
#phoneResult {margin-left:82px;color:red;}
@media screen and (max-width: 575px) {
  #phoneResult {position:absolute;top:5px;margin-left:65px}
}
