/*dorothy - footer Responsive*/
/* .termsUl:after {
    content:'';
    display: block;
    width: 10000px;
    height: 1px;
    background: #bdbdbd;
    position: absolute;
    
} */
footer .snsBtn + ul img {
    filter: grayscale(1);
}
.ant-drawer footer {background: #fff!important; border-top:1px solid rgb(189, 189, 189)}
.ant-drawer footer .copyright { background: transparent!important;  border-top:1px solid rgb(189, 189, 189)}
.ant-drawer footer span { color: #333!important; }
.ant-drawer footer .snsBtn + ul li img {display: none;}

@media only screen and (max-width: 1046px) { 
    
  }
  @media only screen and (max-width: 910px) { 

  }
  @media only screen and (max-width: 768px) { 

  }
  
  @media only screen and (max-width: 684px) { 
      footer {
          padding: 0 15px!important;
      }
      footer .snsBtn {
          position: relative!important;
          bottom: 2px!important;
          text-align: left!important;
          margin-top: 30px!important;
      }
      footer .copyright, footer .ant-divider-horizontal {
          margin-left: -15px!important;
          text-align: center!important;
      }
      
  }
  
  
  @media only screen and (max-width: 418px) {  
    footer .termsUl li {margin: 0 2px!important;}

  }
  