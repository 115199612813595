.ant-layout{
  background-color: #fff;
}

.logo {
    float: left;
    /* width: 120px; */
    height: 31px;
    /* margin: 16px 24px 16px 0; */
    background: rgba(255, 255, 255, 0.3);
  }
  
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: #fff;
  }

  .content-component {
    min-height:100%;
  }

/* antd spin */
.ant-spin{
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-spin-text{
  color:#666666;
  font-weight: bold;
  margin-top: 10px;
}