.search-select{
    width: 100%;
    height: 40px;
    text-align: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 40px;
    border-right: none;
    padding:4px 11px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus,
.ant-input:focus, .ant-input-focused,
.ant-input:hover, .ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused
 {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}
.icon-menu-box {
    /* margin: 0 1rem; */
    display: inline-block;
}
.color-tag-wrap {
    width: 100%;
    margin: 35px 24px 24px;
    /* height: 20px; */
}
.borderLine {
    display: inline-block;
    vertical-align:middle;
    background-color:#FFD560;
    width:176px;
    height:2px;
}
.color-tag-info {
    display: inline-block;
    width:10px;
    height:10px;
    margin-right: 5px;
}
/* card */
.ant-card-body{
    padding:10px;
}
.program-list {
    width:100%;
    margin: 0 auto;
}
.program-list .ant-col {
    /* padding:20px; */
    height:100%;
    max-height: 390px;
}
.program-list-card{
    width:255px;
    /* max-width: 320px; */
    height: 390px;
    /* max-height: 420px; */
    border-radius:10px;
    overflow: hidden;
    border: 1px solid #FFD560;
    position: relative;
}
.program-list-card .card-thumbnail-img{
 max-width:100%;
 width:255px;
 max-height:230px;
 position: relative;
}

.program-list-card .ant-card-cover{
 max-width:100%;
 width:255px;
 max-height:230px;
 position: relative;
 top: 0;
}

.program-list-card .ant-card-body{
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;
}

.card-stste-tag{
    width:50px;
    height:50px;
    position:absolute;
    z-index: 10;
    top: 0; 
    left: 15[x]; 
    text-align: center;
    border-radius: 10px 2px 2px 2px;
}

.card-tag-text{
    color: #ffffff;
    font-size: 10px;
    line-height:50px;
    text-shadow: 0 0 #333;
}
.program-title{
    text-align: center;
    margin-top: 2.5px;
    /*dorothy - title text 넘침 방지*/
    text-overflow: ellipsis;
    width: 235px;
    white-space: nowrap;
    overflow: hidden;
}
.program-title span{
    font-size: 1.25rem;
    font-weight: 700;
}

.program-info{
    margin: 0 auto;    
    width: 92%;
    padding: 10px;
    padding-bottom: 0;
    text-align: center;
}
.program-info ul {
    padding:0;
}
.program-info li{
    font-size: 0.9rem;
    line-height: 23px;
    text-shadow: 0 0 #333;
}
.program-info-title {
    text-align: left;
    display: inline-block;
    width: 38%;
    
}
.program-info-title li {
    text-shadow: 0 0 #333;
}
.program-info-text {
    text-align: left;
    display: inline-block;
    width: 62%;
}

.program-info-text li{
    color: #333333;
    
}

.ant-pagination{
    margin-top: 125px;
    position: relative;
    bottom: -10px;
  }
.cardImgWrap{
    width: 100%;
    height: 230px;
    margin:0 auto;
    padding: 0;
}
.skeletonPapa{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
}


.ant-skeleton-element .ant-skeleton-image{
    width: 255px;
    height: 230px;
}

@media screen and (max-width: 1336px) {
    .program-list {
        width:100%;
    }
}

/* dorothy - Responsive*/
.categoryBtn .icon-menu-box { min-width: 65px; margin-bottom: 2em;}
.categoryBtn .programIcon .ant-space-item { margin-right: 10px;}
.categoryBtn .programIcon .ant-space-item.all {}
.categoryBtn .programIcon .ant-space-item:nth-child(2) {width: 10px; }

@media only screen and (max-width: 1046px) { 
}
@media only screen and (max-width: 786px) { 
    .programIcon {width: 100%; justify-content: center}
    .color-tag-wrap {align-items: center; margin-bottom: inherit!important; }
    .programIcon .ant-space-item { margin-right: 8px!important; width: 11%;}
    .programIcon .ant-space-item:nth-child(2) { width: 20px; }
    .categoryBtn .icon-menu-box .iconUl img {width: 40px; min-width: 40px;}
    .categoryBtn .icon-menu-box {min-width: 40px;}
    .categoryBtn .icon-menu-box .iconUl li {font-size: .75rem;}
    .programDetail > div {transform: none;}
    .applyModal {width: 90%!important; top: 10%;}
    .applyModal .ant-modal-content {padding: 0;}
    .applyModal .ant-modal-body {padding: 1.5em .5em;}
}
@media only screen and (max-width: 480px) { 
    .programIcon .ant-space-item {width: 15%; margin-right: 4px!important;}
    .programIcon .icon-menu-box {margin-bottom: 1em;}
    .programIcon .ant-space-item:first-child { width: 85%; padding: 0!important; margin-bottom: 24px; border-bottom: 1px solid #22222220;}
    .programIcon .ant-space-item:nth-child(2) {display: none;} 
    .categoryBtn .icon-menu-box { margin-bottom: 1em;}
    .categoryBtn .borderLine {width: 120px;}
    .categoryBtn .color-tag-wrap {margin: 0;  justify-content: flex-end; padding: 15px;}
    #cardBox {margin-bottom: 1em;}
    .categoryBtn .ant-space-item .borderLine {display: none;}
    .applyModal .applyModalBtn {width: 45%;}
    .applyModal  .ant-typography {font-size: 16px;}
    .applyModal .ant-form-item:last-child {margin: 0!important;}
}