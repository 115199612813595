  ul, li {
    list-style: none;
  }
  .navMenu a,
  .submenu a,
  a:active,
  a:hover,
  a:focus {
    color: #222222 !important;
  }
  .header-navigation {
    background-color: #fff;
    height:120px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .navtextUl{
    text-align: right;
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 200;
  }
  .navtextUl li {
    display: inline-block;
    margin: 0 6px;
  }
  .navtextUl li a{
    color: #828282;
  }
  .gnb {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .navUl {
    width:100%;
    height:120px;
    padding: 0;
    margin: 0 auto;
    text-align: left;
  }

  .navUl > li {
    display: inline-block;
    height:120px;
    padding-top: 10px;
  }
  .logo{
    margin: 0 4% 0 2%;
  }
  .logo a {
    display: inline-block;
    width: 100%;
    line-height:120px;
  }

.navMenu{
  position:relative;
  height:100%;
  width: 120px;
  text-align: center;
}

.navMenu > a {
  line-height: 120px;
  font-weight: 700;
  font-size: 18px;
  display: block;
  width: 120px;
  color: #444!important;
}

.navMenu a:hover {
  color: #222222 !important;
}
.dropdownWrap{
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  width: 100vw; /*dorothy*/
  z-index: 99;
  height: 100%;
  min-height:40vh; /*dorothy*/
  max-height: fit-content;
  border-top:1px solid #F2F2F2;
  margin: 0 auto;
}
.dropdownWrap::before {
  content: '';
  display: block;
  position: absolute;
  width: 10000px;
  height: 40vh;
  background-color: #FFF;
}/*dorothy*/

.snsUl{
  position: absolute;
  z-index: 100;
  /*dorothy*/
  left: 0;
  right: 0;
  bottom: -65px;
  text-align: right;
  background: #fff;
  padding: 1em;
}
.snsUl li {
  display: inline-block;
  margin: 0 6px;
}

.submenuUl {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 100;
  height: fit-content;
  min-height:40vh;
  display: inline-block;
  padding: 18px; /*dorothy*/
  border: 1px solid #F2F2F2;
  border-bottom: none;
  vertical-align: top;
  margin: 0 auto;
  background-color: #fff;
}

.submenuDiv {
  background-color: #fff;
}
.submenuUl li {
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  color: #222222;
  font-size: 1rem;
  text-align: left;
  display: inline-block;
  width: 100%;
  margin: 0 auto;

}
.submenuUl li a{
  text-align: center;
}
.submenuUl li a:hover {
  font-weight: 400 !important;
  color: #000000;
  text-shadow: 0 0.45px 0 black;
}

.subdep a {
  font-size: 1rem !important;
  color: #828282 !important;
  padding-left: 4px!important;
}

/*dorothy 0416 - hambuger */
.hamburgerBox {
  float: right;
  height: 120px;
  padding-top: 10px;
  margin:0 16px 0 0;
}
.hamburger {border: 1px solid #ddd; padding: 6px; transform: translateY(48px); }
.hamburger .line{
    width: 30px;
    height: 2px;
    background-color: #828282;
    display: block;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .hamburger:hover{
    cursor: pointer;
  }
#hamburger:hover .line:nth-child(1),
#hamburger:hover .line:nth-child(3){
  width: 20px;
}
#hamburger:hover .line:nth-child(1){
  -webkit-transform: translate(10px,2px) rotate(45deg);
  -ms-transform: translate(10px,2px) rotate(45deg);
  -o-transform: translate(10px,2px) rotate(45deg);
  transform: translate(10px,2px) rotate(45deg);
}
#hamburger:hover .line:nth-child(3){
  -webkit-transform: translate(10px,-2px) rotate(-45deg);
  -ms-transform: translate(10px,-2px) rotate(-45deg);
  -o-transform: translate(10px,-2px) rotate(-45deg);
  transform: translate(10px,-2px) rotate(-45deg);
}

/*dorothy 0420 - Header Responsive*/
/* .submenuUl li, .navtextUl {transform: skew(-0.1deg)} */
.submenuUl {transform: none;}
.gnb {max-width: 1500px;}
.navUl > li.logo:hover::after {display: none;}
.navUl > li:hover::after {
  content: '';
  display: block;
  width: 120px;
  height: 3px;
  background: #222;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  min-width: 100%;
}
.dropdown {width: 1470px; max-width: 100vw; height: 100%; position: relative; margin: 0 auto;
  background-color: #fff;
  background-image: url('../img/navBg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.board-main-wrap .ant-layout .ant-layout-header {position: relative;}

.gnb .onlyMobile {display: none !important;}
@media only screen and (max-width: 1046px) {
  .navUl .logo { margin: 0; margin-right: 1em;}
  .navUl > li:hover::after,
  .navMenu {width: 11%;}
  .navMenu > a {width: 100%; font-size: 16px;}
  .hamburgerBox {padding: 0;}
  .ant-layout-sider {display: none;}
  .submenuUl {min-height: 40vh;}
  .dropdown {background-image: none;}
  /* 프로그램 */
  .board-main-wrap .ant-row.program-list {justify-content: center;}
}

@media only screen and (max-width: 768px) {

  .header-navigation {
    position: fixed;
    top: 0;
    z-index: 10001;
  }
  .ant-layout-header { margin-top: 120px;}
  .navUl > li:hover::after,
  .navMenu,
  .submenuUl {width: 86px;}
  .submenuUl {padding: .5em; min-height: 30vh;}
  .hamburgerBox {margin: 0;}
  .hamburger {padding: 4px;}
  .hamburger .line { width: 22px; margin: 4px auto;}
  .navtextUl {right: -4px;}
  .snsUl { bottom: -30px;}

}

@media only screen and (max-width: 418px) {
  .navUl > li:hover::after {display: none;}
}


/* mobile dorothy */
.ant-drawer-open {z-index: 100000;}
#Mobile-nav {
  text-shadow: 0 .5px 0 #333;
  font-size: 16px;
  display: none;
  position: absolute;
  right: 0;
}
.ant-drawer {
  height: 100vh!important;
}

.ant-drawer-content-wrapper {
  width: 100vw!important;
  box-shadow: none!important;
  top: 50px;
  border: none;
}
.ant-drawer .ant-drawer-body { padding: 0;}
.ant-drawer .ant-tabs {
  flex-direction: row;
  width: 100vh;
  border-top: 1px solid #dbdbdb;
}
.ant-drawer .ant-tabs-nav-list {
  flex-direction: column;
}
.ant-drawer .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  width: fit-content;
  float: left;
  display: block;
  margin: 0;
}
.ant-drawer .ant-tabs-content-holder {
  float: left;
  width: 50%;
  display: block;
  border-top: 1px solid #f0f0f0;
  padding: 1em 2em;
}
.ant-drawer .ant-tabs-tab {
  line-height: 4;
  width: 150px;
  background: #FFD560;
}
.ant-drawer .ant-tabs-nav-list .ant-tabs-tab:nth-child(5) {
  /* padding-bottom: 10em; */
  position: relative;
}
.ant-drawer .subListUl-m {
  list-style: none;
  padding: 0;
}
.subListUl-m li {
  line-height: 3;
}

.subListUl-m li::before {
  content: '-';
  margin-right: 8px;

}
.subListUl-m li .anticon {
  margin-left: 6px;
  color: #BDBDBD;
}
.subListUl-m ul {padding: 0 1em;}
.subListUl-m ul li,
.ant-drawer .subListUl-m ul li {font-weight: normal;}
.ant-tabs-nav-list .ant-tabs-tab:nth-child(5)::after {
  content: '';
  display: block;
  background: #FFD560;
  height: 500px;
  right: -1px;
  left: 0;
  position: absolute;
  top: calc(100% + 1px);
}
.ant-drawer .ant-tabs, .nav-wrapper {
  height: calc(100vh - 402px);
  min-height: 500px;
}
.ant-tabs-nav-list, .ant-tabs-nav-wrap {height: 100%;}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {border-color: #f0f0f0; padding: .5em 2em;}
/* open btn */
.movile-nav-openBtn.ant-btn {
  height: 50px;
  width: 50px;
  border: 0;
}
/* close btn */
.ant-drawer .ant-drawer-mask {
  background-color: #fff;
  right: 0;
  left: auto;
  width: 50px;
  height: 50px!important;
  z-index: 100;
  /* top: -50px; */
}
.ant-drawer .ant-drawer-mask::before {
  content: '';
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  background: url('../img/close.svg') no-repeat center;
}
/* antd color custom */
.ant-drawer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { color: #000000;}
.ant-drawer .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff!important;
  color: #fff;
}
.ant-drawer .ant-tabs-tab:hover,
.ant-drawer .ant-tabs-tab {
  background: #FFD560!important;
}
/* btn color custom */
header .ant-btn:active,
header .ant-btn:hover, header .ant-btn:focus { color: #000000!important; border: none; padding: 1em;}


/* mobile */
@media only screen and (max-width: 786px) {
  /* mobile menu  */
   body { padding-top: 60px}
   .header-navigation {
     height: 60px;
     padding: 5px 0 0;
     /* margin: 0 0 5px!important; */
     /* padding: 0!important;  */
     position:fixed;
     top: 0;
     left: 0;
     right: 0;
    }
  .navUl > li.navMenu, .dropdownWrap, .navtextUl .textNav, .hamburgerBox {display: none;}
  .boardLayout {margin-top: 6em;}
  .navtextUl .textNav:nth-child(3), .gnb .onlyMobile {display: inline-block !important;}
  #Mobile-nav {display: inline-block;}
  .navtextUl { padding: 0; top: 14px; right: 45px;}
  .logo a {line-height: 1;}
  .logo img {     height: 24px;    margin-top: 6px;}
  .navUl {height: 50px;}
  .navUl > li{height: 50px; padding-top: 10px; padding-left: 10px;}
  .logo-m img {display: block; height: 30px;}
  .navtextUl span.anticon { background: #ffd560; border-radius: 6px; padding: 2px; margin: 0 4px; display: inline-block;}
  /* .board-main-wrap .ant-layout:first-child, .bw-titleWrap { margin-top: 50px;} */
}

/* loading icon color */
.ant-spin-dot-item {background-color: #222;}

/* alert 메시지 스타일 */
.alert-result-true{
  position:absolute;
  width:90%;
  opacity:0.75;
  top:5%;
  left: 50%;
  right:50%;
  transform:translate(-50%, 50%);
  z-index: 1000000;
  color: black;
  font-weight: 700;
  border-width: 2px;
}


/* mobile 성북아카이브 외부링크버튼 추가 */
.onclicklink {
  position: absolute;
  top: 362px;
  width: 150px;
  height: 68px;
  text-indent: -999px;
}
.navMenu.achieve a {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}
.boardLayout {margin-top: 0;}
