/* Mypage login/join tab css */
.logcontainer {max-width: 1000px; width: 100%; margin: 0 auto; padding: 1em 0;font-size: 16px;}
.loginTabBox {width: 470px; height: 700px; border:1px solid #BDBDBD; margin: 0 auto; max-width: 98%;}
.loginTabBox button { display:block; margin: 0 auto 12px auto; color:#333; text-align: left; border-radius: 2px; border: 0; outline: none; text-shadow: 0 0 black;}
.loginTabBox .ant-tabs-nav-list {width: 100%;}
.loginTabBox .ant-tabs-nav-list .ant-tabs-tab {width: 50%;}
/* SNS button */
.loginTabBox .naverBtn {background: #27AE60; color: #fff;}
.loginTabBox .googleBtn {background: #FFFFFF; border: 1px solid #E0E0E0;}
.loginTabBox .kakaoBtn {background: #FFE600;}
.loginTabBox .facebBtn {background: #2F80ED; color: #fff}
/* SNS button icon */
.loginTabBox .before::before {content: ''; display: inline-block; width: 38px; height: 38px; margin: 0 12px; vertical-align: middle;}
.loginTabBox .naverBtn::before {background: url('../img/icon-naver.svg');}
.loginTabBox .googleBtn::before {background: url('../img/icon-google.svg');}
.loginTabBox .kakaoBtn::before {background: url('../img/icon-kakao.svg');}
.loginTabBox .facebBtn::before {background: url('../img/icon-facebook.svg');}
/* loginTab */
.loginTabBox .loginTab button {width:300px; height: 70px; padding-left: 25px; font-size: 19px;}
.loginTabBox .loginTab .joinBtn { margin-top: 3em; background: transparent; text-align: center; padding: 0;}
.loginTabBox .loginTab .joinBtn p {font-size: 1.5em;}
/* joinTab */
.loginTabBox .joinTab button {width: 48%; padding: .3em 0; float: left; box-sizing: border-box;}
.loginTabBox .joinTab button:nth-child(2n) {margin-left: 15px;}
.loginTabBox .termsBox {margin: 1em 0 2em; text-align: left;}
.loginTabBox .termsBox p { font-size: 16px; text-shadow: 0 1px 0 black;}
.loginTabBox .joinTab .terms {margin: 1em 0; height: 120px; }
.loginTabBox .joinTab .terms>div {width: 100%; height: 120px;  border: 1px solid #F2F2F2; overflow:scroll}
/* antd custom */
/* .loginTabBox .ant-tabs-nav-list { transform: translate(-2px, 0px)!important;} */
.loginTabBox .ant-tabs-content-holder {padding: 0 15px; margin-bottom: 3em;}
.loginTabBox .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .loginTabBox .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {height: 68px; color:#fff; background: #828282; margin: 0!important; border-radius: 0!important; opacity: .5; border:0; border-top: 3px solid #828282}
.loginTabBox .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover, .loginTabBox .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab:hover {opacity: 1;}
.loginTabBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .loginTabBox .ant-tabs-tab-btn {margin: 0 auto; font-size: 1.5em;}
.loginTabBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color: #000;}
.loginTabBox .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .loginTabBox .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {background: #fff; color:#000; opacity: 1; width: 70%;}
.loginTabBox .ant-tabs-nav-operations, .loginTabBox .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {display: none;}
.loginTabBox .loginTab.ant-tabs-tabpane { margin-top: 10%;}
.loginTabBox .ant-tabs-tab:hover {color:#000}
.loginTabBox .ant-checkbox-checked .ant-checkbox-inner {background-color: #fff; border-color: #27AE60;}
.loginTabBox .ant-checkbox-wrapper:hover .ant-checkbox-inner, .loginTabBox .ant-checkbox:hover .ant-checkbox-inner {border: 1px solid #222;}
.loginTabBox .ant-checkbox-input:focus + .ant-checkbox-inner, .loginTabBox .ant-checkbox-checked::after { background-color: transparent; border: 2px solid #27AE60;}
.loginTabBox .ant-checkbox-checked .ant-checkbox-inner::after {border: 2px solid #27AE60; border-top: 0; border-left: 0;}
.loginTabBox .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .loginTabBox .ant-tabs-tab-btn:active, .loginTabBox .ant-tabs-tab-remove:active { color: #fff;}

/* Mypage common css */
.mycontainer {max-width: 1000px; margin: 0 auto;}
.mycontainer h2 {text-shadow: 0 .5px 0 #333; margin-top: 1em;}
.mypageBox {list-style: none; margin: 0 auto; padding: 0; width:100%; max-width: 630px;}
/* Mypage  css */
.mypageNav li { margin:20px 0; padding: 3em; border:1px solid #BDBDBD}
.mypageNav li a {color: #222;}
.mypageNav li h3 {font-size: 22px; text-shadow: 0 0 #333; margin-bottom: 8px;}
.mypageNav li p {font-size: 14px; margin: 0;}
.mypageNav li .anticon {position: absolute; top: 50%; right: 5%;}
/* MyInfo css */
.myinfo .mypageBox th, 
.myinfo .mypageBox td {border: 1px solid #BDBDBD; padding: 1em;}
.myinfo .mypageBox th { background: rgba(204, 227, 223, 0.2); padding: 1.25em 2em;}
.myinfo .mypageBox input {border: 0; outline: 0;}
.myinfo .mypageBox .changeinput input {border: 1px solid #D4D4D4}
.myinfo .mypageBox .changeinput sub { padding-left : 1em;}
/* MyAction css */
.myaction .contentsWrapper {margin: 5em 0 ;}
/* antd custom - MyAction*/
.myaction .ant-steps-item-finish .ant-steps-item-icon {border-color: #CCE3DF;}
.myaction .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {background-color: #CCE3DF; height: 2px;}
.myaction .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {background: #CCE3DF; border-color: #CCE3DF;}
.myaction .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {color: #CCE3DF;}
.myaction .ant-table-thead > tr > th {background: #CCE3DF20; border: 1px solid #E0E0E0;  text-align: center ;}
.myaction .ant-table-tbody > tr > td {border: 1px solid #E0E0E080}
.myaction .ant-table-cell, .myaction .ant-steps {text-align: center;padding: 1em;}
.myaction .ant-table-cell:nth-child(3) {text-align: left;}
.myaction .ant-steps-item {width: 25%;}
.myaction .ant-steps-item-content {text-align: justify;}
.myaction .ant-btn-dangerous.ant-btn-primary.refundBtn { background: #fff1f0; color: #ff4d4f;}
.myaction .ant-btn-dangerous.ant-btn-primary.cancleBtn { background: #f2f2f2; color: #828282; border-color:#828282}
.myaction .ant-table-thead, .ant-table-thead > tr > th {font-size: 14px;}
.logcontainer {
    display: table;
    height: calc(100vh - 125px);
    border: 0;
    outline: 0;
}
.loginTabBox {
    display: table-cell;
    vertical-align: middle;
    border: 0;
}
.loginTabBox > .ant-tabs { border: 1px solid #BDBDBD; min-height: 730px;}
.mycontainer { 
  text-align: left; 
  font-size: 16px; 
  /* max-width: 1000px;  */
  /* 글 잘림 현상 수정 asha */
  max-width:1200px;
  padding: 15px;
}
.mycontainer h1 {text-align: center; margin: 1em auto}
.mycontainer h2 {width: 750px}
.mycontainer .mypageBox,
.mycontainer .mypageBox table { max-width: 100%; width: 750px;}
.mycontainer .mypageBox .ant-form-item {
    width: 185px;
    display: inline-block;
    margin: 0;
}
.mypageNav li { 
    margin: 20px auto;
    position: relative; 
    min-width: 750px;
}
.mycontainer .ButtonWrapper { margin: 5em 0; text-align: center;}
.mypageBox {font-size: 16px;}
.mycontainer .ant-table table {border-collapse: collapse;}
.myaction .contentsWrapper {margin-top: 0;}
.mycontainer.myaction h2 {width: 100%;}
.mycontainer.myaction .ant-table-container table > thead > tr:first-child th:first-child {padding: 0;}
.myaction .ant-table-thead > tr > th, 
.myaction .ant-table-tbody > tr > td {padding: .75em;}
.mycontainer .List h2 {width: 100%; margin-bottom: 0;}
/* antd - btn */
.mycontainer.myinfo .ant-btn.btnL
/* .mycontainer.myinfo .ant-btn:active, */
/* .mycontainer.myinfo .ant-btn:hover,  */
/* .mycontainer.myinfo .ant-btn:focus  */
{max-width: 175px; width: 47%; margin:0 3% 3% 0; border-color: #ffd560; letter-spacing: 0.25em;  color: #000!important;}/*height: 35px;*/
.mycontainer.myinfo .ant-btn-primary.btnL, .mycontainer.myinfo .ant-btn-primary, .mycontainer.myinfo .ant-btn-primary:hover, .mycontainer.myinfo .ant-btn-primary:focus {background: #FFD560; color: #000; border: 0;}
.mycontainer.myinfo .ant-btn-primary.btnL.qna {position: absolute; right: 0; top: 0; }
.mycontainer.myinfo .ant-btn.btnL:active, #OriginName.container .ant-btn:hover, .mycontainer.myinfo .ant-btn:focus, .mycontainer.myinfo .ant-btn.btnL:hover  {color: #222; background: #FFD560; border-color: #FFD560;}
.mycontainer.myinfo .ant-modal-footer button + button {margin-left: 0;}
.mycontainer.myinfo .ant-btn-primary {margin: 0;}
.mycontainer.myaction .ant-btn-dangerous.ant-btn-primary {padding: 0 5px; width: 90px;}
.mycontainer.myaction .ant-btn-dangerous.ant-btn-primary span {font-size: 14px;}
.mycontainer.myaction .ant-tag {margin: 0; width: 60px;}
.mycontainer.myinfo h2 {
  margin: 2em auto;
  text-align: center;

}
/* inquiry */
.border-grid{
    border: 1px solid #E0E0E0;
    margin: 50px 0px 0px;
    /* padding:0 20px; */
}
.border-grid-row{
  border: 1px solid #E0E0E0;
  border-top: none;
  /* padding:0 20px; */
}
.border-grid-title{
  border-right: 1px solid #E0E0E0;
  /* height:46px; */
  height: auto;
  text-align: center;
}

.border-grid-title span {
  font-size:1rem;
  text-shadow: 0 1px 0 #333;
}
.border-grid .ant-col,
.border-grid-row .ant-col {
  padding:10px;
}
.border-grid-content{
  text-align: left;
}
.border-grid-content span{
  font-size:1rem;
  text-shadow: 0 0 #333;
}

.comment-row{
  border-top: 1px solid #E0E0E0;
  padding-top: 3em;
  margin: 50px auto;
  justify-content: space-between;
}
.comment-row img {
    display: block;
    height: min-content;
    margin: 0 1em 1em 0 ;
}
.ant-comment-content{
  padding:5px 0;
}
.ant-comment-content-detail{
  /* margin:40px auto; */
  background: rgba(204, 227, 223, 0.2);
  min-height: 150px;
  border-radius: 10px;
  padding: 2em;
}
.ant-comment-content-author {
    padding: 1em;
    padding-top: 0;
}
.ant-avatar {
    background-image: url('../img/NoneTextLogo.png');
    background-position: center;
    width: 38px;
    height: 38px;
}
.ant-comment-actions{
  text-align:right;
  position:absolute;
  right:0;
  top:0;
  margin:0
}
.mycontainer .detailMainInfoWrap {padding: 0 20px; justify-content: space-between}
.mycontainer .detailMainInfoWrap .ant-col { max-width: min-content;}
.mycontainer .ant-btn-text.history,
.mycontainer .ant-btn-text.delete { padding: 0;}
#replyform {border: 0!important; padding: 0!important;}
#replyform .ant-col > div {text-align: center; background: rgba(204, 227, 223, 0.2); min-height: 150px; border-radius: 10px; padding: 4em 0;}
#replyform .ant-col .ant-form-item-control-input, #replyform .ant-col .ant-comment {background: transparent; padding: 0;}
.mycontainer .bottomInquiry, .mycontainer .detailFooterWrap {border: 0; padding: 0;}
.mycontainer.myinfo .List .ant-btn {letter-spacing: 0;}
/* antd - pagenation */ 
.ant-pagination{ margin: 3em 0; width: 100%; text-align: center; }
.ant-pagination .ant-pagination-item {font-size: 18px; text-shadow: 0 0 #333; opacity: .5; margin: 0 2px; box-sizing: content-box;}
.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:focus,
.ant-pagination .ant-pagination-item:hover {border-color: #ddd; opacity: 1;}
.ant-pagination .ant-pagination-item-active a, .ant-pagination-item:focus a, .ant-pagination-item:hover a,/*page number color*/
/* pre/next button */
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link { color:#5c5c5c; text-shadow: 0 0 #333;}
.ant-pagination .ant-pagination-prev, .ant-pagination .ant-pagination-next {margin: 0 4px; height: 34px; width: 34px;}

/* alert 메시지 스타일 */
.alert-result-true{
    position:absolute;
    width:90%;
    opacity:0.75;
    top:5%;
    left: 50%;
    right:50%;
    transform:translate(-50%, 50%);
    z-index: 1000000;
    color: black;
    text-shadow: 0 1px 0 black;
    border-width: 2px;
}


/* tag 스타일 Asha */
.statusTag { 
  text-shadow: 0 1px 0 black; 
  border-radius: 0 !important; 
  width: 60px;
  padding: 0;
  margin: 0;
}

/* 마이페이지 목록으로 되돌아가기 버튼 스타일 작업 */
.historyBtn {
  padding: 0;
  margin-top: 1em;
}
/* 1:1 문의 스피너가 왼쪽으로 치우쳐 보이는 레이아웃 수정 요청. */
.myinfo .ant-spin-nested-loading > div > .ant-spin {
  transform: translate(10px);
}

/*Responsive */
@media screen and (max-width:768px) {
    .mycontainer h2, 
    .mypageNav li,
    .mycontainer .mypageBox,
    .mycontainer .mypageBox table { width: 100%; min-width: 100%; }
    .mypageNav li {padding: 3em 4em 3em 2em; min-height: 180px;}
    .myaction .contentsWrapper .ant-table table { table-layout: fixed!important; margin: 0;}
    .myaction .contentsWrapper .ant-table-content { overflow: scroll;}
    .mycontainer .detailMainInfoWrap, .mycontainer .detailMainInfoWrap + .ant-row {padding: 0 1px!important; font-size: 14px;}
    .myaction .ant-steps-item-content, .myaction .ant-steps-item-description {display: block; text-align: center; margin: 0 auto;}
    .logcontainer {padding: 15px;}
    .myinfo .List td.ant-table-cell:nth-child(2) {font-size: 12px; padding: 1em .5em; color: #828282;}
}

@media only screen and (max-width: 480px) {  
  /* mypage */
  .mycontainer {padding: 15px;}
  .changeinput sub { display: none;}
  .mypageNav li {padding-left: 2em;}
  .myaction .ant-steps, .myaction .ant-steps-item-title, .myaction .ant-tag {padding: 0; margin:0}
  .myaction .ant-table table {table-layout: fixed;}
  .myaction .ant-table-thead > tr > th, .myaction .ant-table-tbody > tr > td {  padding: 1em 2px; font-size: .9em;}
  .mycontainer .List .ant-table table {font-size: 14px;}
  .myaction .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {display: none;}
  .mycontainer .detailFooterWrap {margin: 0;}
  /* login */
  .loginTabBox, .loginTabBox .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list, .loginTabBox .joinTab button {width: 100%;}
  .loginTabBox .joinTab button:nth-child(2n) {margin-left: 0;}
  .loginTabBox .joinTab .terms, .loginTabBox .joinTab .terms>div {height: 72px;}
  .mycontainer h1 {font-size: 1.5em;}
  .myinfo .mypageBox th { padding: 1.25em .5em; font-size: .9em;}
  .myinfo .mypageBox .changeinput sub {display: block; padding: 1em 2px;}
  .border-grid .ant-col, .border-grid-row .ant-col { padding: 10px 4px;}
  .border-grid {margin: 1em 0 0;}
}

@media only screen and (max-width: 390px) {  
  div .myinfo .List .ant-table-cell:nth-child(2),
  div .myinfo .List .ant-table-cell:nth-child(4) { color: transparent;}
}