.depth {
    font-size: 14px;
    font-weight: 700;
    color: #7C7C7C;
    line-height: 1;
    margin-bottom: 12px;
}
.title {
    margin-bottom: 27px;
    font-size: 24px;
    font-weight: 700;
}
.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 400;
    color: #555555;
    line-height: 1.5;
    text-align: justify;

}
.galleryTextList .ant-list-item-meta-content {
    display: flex;
    align-items: center;
}

.galleryTextList .ant-list-item-meta-title {
    position: relative;
    top: 0;
    flex-direction: column;
    align-self: center;
    width: 100%;
}

.galleryTextList .dateTime {
    top: auto;
    bottom: 12px;
    font-size: 13px;
    font-weight: 400;
}

.galleryTextList .ant-image {
    max-width: 100%;
}
.galleryTextList .ant-image img {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
}
.galleryTextList .ant-list-item-meta {
    align-items: center;
}
.galleryTextList .depth-row > div {
    text-align: end;
}
.galleryTextList .depth-row .ant-select-selector {
    text-align: center;
}
@media screen and (max-width:1200px) {
    .galleryTextList .ant-list-item-meta-title {
        left: 0;
    }
    .galleryTextList .ant-list-item-meta-content {
        padding:0
    }
    .ant-list-lg .ant-list-item {
        padding-right: 0;
        padding-left: 0;
    }
    .galleryTextList .dateTime {
        right: 0;
    }
}
@media screen and (max-width:768px) {
    .galleryTextList .depth-row > div {
        text-align: center;
    }
    .ant-list-lg .ant-list-item  {
        margin-bottom: 1em;
    }
     /* list type */
     .galleryTextList .ant-list-item-meta-content {width: 100%; min-width: 80vw}
     .galleryTextList .ant-list-item-meta {flex-direction: column;}
     .galleryTextList .ant-list-item-meta-avatar { margin: 0 auto 2em;}
     .galleryTextList  .ant-list-item-meta-description { width: 100%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap }
   
}
@media screen and (max-width:480px) {
    .galleryTextList .ant-image,
    .galleryTextList .ant-image img {
        width: 100%!important;
        height: auto!important;
    }
}