.static.container th,.static.container td, #OriginName .ContentWrapper p,.static.titleLayout .contents {
    letter-spacing: -.5px;/*dorothy*/
    color: rgb(58, 58, 58);
}
/* HTML5 display-role reset for older browsers */
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button{
    cursor: pointer;
    outline: none;}
input{
    outline: none;
    padding-left: 10px;}
strong {text-shadow: 0 0 #333}
hr {border: 1px solid #E0E0E080;}
.static.container {
    margin: 0 auto;/*dorothy - center정렬*/
    /* transform: translateX(-100px);dorothy - center정렬 */
    position: relative;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    text-align: left;/*dorothy*/
    font-size: 16px;
}

/*.static.container *{transform: skew(-0.1deg);}*/
.static.container img {display: block;}
.clear {clear: both;}
.dblock {display: block;}
/* antd custom - Button  */
.static.container .ant-btn,
.static.container .ant-btn:active,
.static.container .ant-btn:hover,
.static.container .ant-btn:focus {width: 175px;  margin:0 3% 3% 0; border-color: #ffd560; letter-spacing: 0.25em;  color: #000!important;}/*height: 35px;*/
.static .ant-btn-primary.btnL, .static .ant-btn-primary, .static .ant-btn-primary:hover, .static .ant-btn-primary:focus {background: #FFD560; color: #000; border: 0;}
.static .ant-btn-primary.btnL.qna {position: absolute; right: 0; top: 0; }
.static.container .ant-btn:active, #OriginName.container .ant-btn:hover, .static.container .ant-btn:focus, .static.container .ant-btn:hover  {color: #222; background: #FFD560; border-color: #FFD560;}
.static .ant-modal-footer button + button {margin-left: 0;}
/* antd custom - input alert (Inquiry)*/
.static .ant-form-item-explain.ant-form-item-explain-error {font-size: 0.85em;}
.static .ant-form-item-label > label::after {opacity:0}
.static .ant-form-horizontal .ant-form-item-label { text-align: left; text-shadow: 0 0 #333; font-size: 16px;}
.static .ant-input:focus, .static .ant-input-focused, .static .ant-input:hover, .static .ant-cascader-picker:focus .static .ant-cascader-input {border-color:#ffd560; box-shadow: 0 0 0 2px #ffd56050; -webkit-box-shadow: 0 0 0 2px #ffd56050;}
.static .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .static .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover, .static.ant-cascader-menu-item-expand {background: #E0E0E090; width: 168px;}
.static .ant-cascader-menu-item {width: 168px;}
.static .ant-form-item { width: 80%; margin: 0 auto 1.25em;}
.static .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {position: absolute; right: 0; top: 0;}

/* antd custom - modal (Support)*/
.static .ant-modal-root {height:100vh; position: absolute; top: 0; left: 0; bottom: 0; right: 0;}
.static .ant-modal-header {border: 0;padding-top: 3em;text-align: center;}
.static .ant-modal-footer {text-align: center; border: 0; padding-bottom: 3em;}
.static .ant-modal-title {font-weight:700; font-size:20px; line-height:2;}
.static .ant-modal-title::before {content:""; display:block; background:url('../img/draft-fill.svg'); width: 23px; height: 25px; margin: 0 auto;}
/* common */
.static.KeywordWrapper{margin: 1% 0;}
.static.Keyword { cursor: pointer; border: 1px solid #FBB03B; outline: 0; width: 65px; height: 24px; border-radius: 18px; margin: 0 6px 6px 0; background:white; line-height: 1; transition: ease border-bottom 250ms; background-color: white}
.static .Keyword.active {background-color: #FFD560;}
.static .Keyword:hover, .Keyword:focus {background:#FFD560;}
.static .Content.active {display:none}
.static.container div.ContentWrapper {margin: 5% 0; border: 1px solid #dddddd80; padding: 1em; }
.static .ButtonWrapper{text-align: center;  margin: 6%;}
.static .Maintitle h2 {font-size: 32px; text-shadow: 0 1px 0 #333;}
.static.titleLayout .Maintitle { background: #fff; background-size: cover; height: 300px;}
/*.static.titleLayout .Maintitle::before {content:''; display: block; position: absolute; bottom: -1px; left: -1px; min-width: 542px; height: 160px;
    background: rgb(255,213,96); background: linear-gradient(90deg, rgb(247, 220, 127) 10%, rgb(218, 245, 234) 100%)}*/
.static.titleLayout .Maintitle > div { position: absolute;  bottom: 0; padding: 0 4% 0; }
.static.titleLayout .contentsWrapper {padding: 5% 0 0 0;}
.static.titleLayout .contents {font-size: 1rem; text-align: justify;}
.static.titleLayout .ContentTitle {  border-bottom: 1px solid #f1f1f1;  line-height:2; font-size: 1.4em; text-shadow: 0 0 #333;}
.static .Subtitle {font-size: 1.25em; text-shadow: 0 0 #333; border-bottom:1px solid #FFD560;  line-height: 2;}
.static .Subtitle::before, .static p.TableTitle::before, .static .MenuRight::before  {content: '';background: url('../img/subtitle-before.svg'); display: inline-block; width: 20px; height: 10px; margin-right: 6px;}
.static .MenuRight {display: inline-block;  vertical-align: top;  width:20%;  text-shadow: 0 0 #333; padding-left: 3px;}
.static .MenuLeft {display: inline-block;  width:80%;  margin-bottom: 3em;  }
.static .SubMenu {text-shadow: 0 0 #333;}
.static .Menu {margin-bottom:5em;    margin: 2%;}
.static.container th, .static.container td {font-size: .9em;}
/* CourseGuide */
#CourseGuide .Subtitle {margin-top: 3em;}
#CourseGuide .Subtitle:first-child {margin-top: 0;}
#CourseGuide .SubWrapper { line-height: 1.5; margin: 1em 0 3em;}
#CourseGuide .SubWrapper h3 { text-shadow: 0 0 #333; font-size: 18px;}
#CourseGuide .SubWrapper li {list-style: circle;}
#CourseGuide .SubWrapper b, .SubWrapper span { font-size: 0.9em; padding-left: 1.25em; margin:4px; color: #828282;}
#CourseGuide .Account { border:2px solid #E0E0E0; line-height: 2; text-align: center; margin: 30px 30px 5em; padding: 1em;}
#CourseGuide .Account::before { display: inline-block; content:""; width: 25px; height: 25px; background-image:url('../img/wooribank.jpg'); vertical-align: middle; margin-right:10px;}/*dorothy-3*/
#CourseGuide .SubWrapper h3::before {content: '●'; color: #FFD560; }

/* Supports */
#Support .ant-form-horizontal .ant-form-item-label {text-align: right; margin-right: 5%;}
#Support .title {margin-bottom: 1.25em;}
#Support .MenuRight {font-size: 1.25em;}
#Support .SubMenu {margin: 0 0 1em; font-size: 1.15em;}
#Support .Menu {margin: 0;}
.ant-modal-confirm-success .ant-modal-confirm-btns .ant-btn-primary {background: #ffe58f; border-color: #FFD560; color: #222;}
#Support .static.container {max-width: 100%; text-align: center;}
#Support button {cursor: default; font-size: 1em; line-height: 1; height: 40px;}
#Support .ButtonWrapper button {cursor: pointer;}
/* Greetings */
#Greetings h3, #Greetings h2, #Greetings p {color: #000; margin: 0;}
#Greetings h3::after {content: ''; display: block; width: 2px; height: 2em; background-color:#03569f; margin: 5px;}
#Greetings.titleLayout .Maintitle > div {text-shadow: none;margin-bottom: 1.75em; position: absolute; bottom: 0;}
#Greetings .Maintitle {background: url('../img/greeting-03-.jpg') no-repeat center; min-height: 550px; position: relative;}
#Greetings .Maintitle::after {display: none;}
/* Milestones */
#Milestones .HistoryList {overflow:hidden;}
#Milestones .HistoryList::before {content: "";display: block; position: absolute;background: #ddd; width: 1px; top: 225px; bottom: 38px;; right: 50%;}
#Milestones .List {width:50%; margin:1px; position: relative; float : right; text-align:right;}
#Milestones .Listul {width:90%; padding:4px 8px; background:#fff; border:1px solid #ddd; position:relative; text-align : left; float : right;}
#Milestones .Listul.last {background-color :#f3fbf6;}
#Milestones .List:nth-child(2n-1) {float:left}
#Milestones .List:nth-child(2n-1) div {text-align : right; }
#Milestones .List:nth-child(2n-1) .Listul {float: left;}
#Milestones .List:nth-child(2n-1) .Line {left: auto; right:-2px; transform: rotate(180deg)}
#Milestones .Listli {padding: 5px 0; line-height: 1.25; letter-spacing: -0.5px; list-style: none; }
#Milestones .Listli.year {font-size :24px; text-shadow: 0 0 #333; color : #017167; margin-bottom :10px;}
#Milestones .Date {color:#828282; display: inline-block; width: 12%; min-width: 45px; vertical-align: top; font-size: 14px;}
#Milestones .List:nth-child(2n-1) .Date { float: right;  margin: 0;} /*06.23 D*/
#Milestones .HistoryTit {display: inline-block; max-width: 88%; vertical-align: top; font-size:16px;word-break: keep-all;}  /*연혁 word-break added by Sarc 210624*/
#Milestones .Line {position: absolute;top: 45px; left: -4px; right: auto; transform: rotate(0); }
/* Employees */
#Executives .ant-table-thead > tr > th { background: #fafafa; border:0; border-bottom: 1px solid #f0f0f0; text-align: center; padding: 20px 0; }
#Executives .ant-table-tbody > tr > td { border:0; border-bottom: 1px solid #f0f0f0; text-align: center;}
#Executives .ant-table-tbody > tr > td:nth-child(4) {text-align: left; padding-left: 5%;}
p.TableTitle {margin: 30px 0 12px 0; font-size: 1.25em; line-height: 2; text-shadow: 0 0 #333; padding: 0 10px; border-bottom: 1px solid #ffd560;}
/* Familysite */
#SiteData .ant-card-cover img { padding: 4% 18%; border-bottom: 1px solid #ddd;}
#SiteData .ant-col {padding:3% 1% 0 1%!important; width: 33%; float: left;}
#SiteData .ant-card-body {padding: 8px;}
#SiteData .ant-card-bordered {border-color: #ddd; text-align: center;}
#SiteData .ant-row {display: flex; justify-content: center;}
#SiteData .site-card-wrapper {width: 100%; margin: 0 auto;}
#SiteData h2, #SiteData p {margin-left: 4px;}
/* Map */
.static .LeftBox {width: 48%; float: left; margin: 0 1% 3em 0;}
.static .RightBox {width:50%; float:right;}
#maps #Map {width:100%; height:600px; background:#000;}
#maps a.Icon {display: inline-block; margin: 30px 10% 50px 0; font-size: 12px; text-decoration: none; color: #222;}
#maps img.Iconimg {margin: 0 auto 5px;}
#maps .LeftBox .ContentsWrapper { font-size: 14px; text-align: left; border-top: 1px solid #E0E0E080; padding: 1em .5em;}
#maps .Btni {float: left; font-size: 30px; padding: 0 16px 0 0;  margin: 24px 0; width: 40px;}
#maps .Btni + p {margin: .5em;}
/* OranizationChart */
#OranizationChart .CharWrapper {text-align: center; padding: 0;}
#OranizationChart .CharWrapper::after { content:''; display:block; width:1px; height:75%; background:#ccc; position: absolute; bottom: 0; left: 50%; z-index: -1;}
#OranizationChart .CharWrapper div .Chart{display: inline-block; margin: 10px;border: 1px solid #ddd;}
#OranizationChart .Chart { float: none; width: 200px; text-align: center; list-style: none; margin: 45px auto; position: relative;}
#OranizationChart .CharWrapper > * {position: relative;}
#OranizationChart .CharWrapper div::before{  content:''; display:block; height:100vh; background:#ccc; position: absolute; width: 660px; height: 1px; top: -10px; left: 1px; right: 0; margin: 0 auto;}
#OranizationChart .Chart::after{  content:''; display:block; width:1px; height:100vh; background:#ccc; position: absolute; width: 1px; height: 45px; top: -45px; left:50%; }
#OranizationChart .Chart3 .Chart::after,
#OranizationChart .Chartdiv .Chart::after,
#OranizationChart .CharWrapper div:last-child .Chart::after {  height: 20px; top: -20px; }
#OranizationChart .CharWrapper > .Chart:first-child::after {display: none;}
#OranizationChart .Chart ul { padding: .75em .75em 0 1.5em;  background: white;  height: 120px;  overflow: hidden;  line-height: 1.5; text-align: left; font-size: .9em;}
#OranizationChart .Chart li { list-style-type: disc; line-height: 1.75;}
div .Chart p {background: #F2F2F2;  margin: 0;  line-height: 48px; }
#OranizationChart .Chartdiv {margin:45px 0;}
#OranizationChart .CharWrapper div.Chartdiv::before {width: 220px;}
#OranizationChart .CharWrapper > li.Chart:first-child p{background: #FFD560}
#OranizationChart .CharWrapper > li.Chart:nth-child(2) p{background: #CCE3DF;}
#OranizationChart .CharWrapper > div.Chart3 li.Chart p {background: #e9f5ed;}
#OranizationChart .CharWrapper > li.Chart:nth-child(4) p{background: white; border: 1px solid #ddd;}
#OranizationChart .CharWrapper > li.Chart:nth-child(5) p{background: #ddd; border: 1px solid #ddd;}
#OranizationChart .CharWrapper > div.Chartdiv li.Chart p {background: #828282; color: white;}
#OranizationChart .OranizationChart-img img {width: 100%; max-width: 600px; margin: 0 auto;}
/* Inquiry */
#Inquiry .title { text-align: right;   margin-right: 5%;}
#Inquiry .ant-form { margin-top: 4%;}
#Inquiry span.ant-typography { margin:0 auto}
#Inquiry .ant-form-item .ant-form-item-label > label { padding: 0 1em 0 0}
/* OriginName */
#OriginName .ant-btn.KeywordOnmap {  cursor: pointer;  list-style:none;  position:absolute;  z-index:1;  width: 80px;  height: 50px; opacity: 0;}
 .KeywordOnmap:nth-child(1) {display: none;}
 .KeywordOnmap:nth-child(2) {top: 380px;left: 310px;}
 .KeywordOnmap:nth-child(3) {top: 460px;left: 306px;}
 .KeywordOnmap:nth-child(4) {top: 513px;left: 264px;}
 .KeywordOnmap:nth-child(5) {top: 630px;left: 265px;}
 .KeywordOnmap:nth-child(6) {top: 582px;left: 200px;}
 .KeywordOnmap:nth-child(7) {top: 370px;left: 600px;}
 .KeywordOnmap:nth-child(8) {top: 475px;left: 60px;}
 .KeywordOnmap:nth-child(9) {top: 580px;left: 327px;}
 .KeywordOnmap:nth-child(10) {top: 400px;left: 450px;}
 .KeywordOnmap:nth-child(11) {top: 320px;left: 512px;}
 .KeywordOnmap:nth-child(12) {top: 350px;left: 135px;}
 .KeywordOnmap:nth-child(13) {top: 474px;left: 393px;}
#OriginName .ant-btn {width: fit-content; padding: 0 6px; margin: 8px 8px 8px 0; letter-spacing: 0;min-width: 52px;}
#OriginName div .ant-btn:first-child {background: #ffd560; margin-right: 25px; border: none; text-shadow: 0 0 #333;}
#OriginName div .ant-btn:first-child::after { content: ''; display: inline; position: absolute; right: -12px; width: .5px; height: 70%; background: #ddd;}
#OriginName .ContentWrapper p {text-align: justify;line-height: 1.5;}
#OriginName .ContentWrapper div:first-child {display: none;}
#OriginName .KeywordOnmapWrapper {position: absolute;top:0;left: 0;right: 0;}
#OriginName .MapImg {margin: 50px auto; filter: grayscale(1);}
#OriginName .ContentWrapper div {margin-bottom: 1.75em;}
/* Business */
#Business .Maintitle::before {content:''; display: block; position: absolute; bottom: -1px; left: -1px; min-width: 542px; height: 160px;
  background: rgb(255,213,96); background: linear-gradient(90deg, rgb(247, 220, 127) 10%, rgb(218, 245, 234) 100%)}
#Business .contents > div { float: left;}
#Business .contents div:first-child { width:30%; overflow: hidden;}
#Business .contents div:nth-child(2) {width: 70%; margin-top: 1em;}
#Business .contents li{ margin-left: 18px; padding: 4px 0; font-size: 16px; list-style: disc;}
#Business .contents img {padding: 1em 0; transform: translateX(-12%);}
#Business .Maintitle {background: url('../img/business-bg.jpg') no-repeat right;position: relative;}
/* Archive */
#Archive .Maintitle {background: url('../img/achive-bg.jpg') no-repeat right;position: relative;}
#Archive .Maintitle::before {content:''; display: block; position: absolute; bottom: -1px; left: -1px; min-width: 542px; height: 160px;
  background: rgb(255,213,96); background: linear-gradient(90deg, rgb(247, 220, 127) 10%, rgb(218, 245, 234) 100%)}
#Archive .ant-btn, #Archive .ant-btn:active, #Archive .ant-btn:hover, #Archive .ant-btn:focus { width: 18.4%; height: 70px;  padding: 1%; margin: 1%; letter-spacing: 0;}
#Archive .ant-btn {font-size: 1em;}
#Archive .ant-btn:first-child {margin-left: 0;}
#Archive .ant-btn:last-child {margin-right: 0; }
#Archive .contents { line-height: 1.5; font-size: 1em;}
#Archive .Subtitle {border:none; font-size: 1em;margin: 0; display:inline-block}
#Archive .Subtitle + span {display: inline-block;}
/* sitemap */
.Sitemap {font-size: 16px; }
.Sitemap .nav-wrapper ul { padding: .25em 1em; margin: 0;}
.Sitemap .nav-wrapper > ul { padding: 0; text-align:center; }
.Sitemap .nav-wrapper > ul > li {
  width: 210px;
  height: 370px;
  border: 1px solid #CCE3DF;
  box-sizing: border-box;
  margin: .5em;
  margin-top:0;
  float: left;
  text-align: left;
}
.Sitemap .nav-wrapper > ul > li > div {
  background: #CCE3DF;
  margin: 4px 4px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 4;
}
.Sitemap .subListUl-m li {
  line-height: 2.5;
}
.Sitemap .subListUl-m ul li {
  line-height: 2;}
.Sitemap .subListUl-m {display:inline-block}

/* 22.08.30 named 수정 */
#OriginName .ant-btn {
  font-size: 16px;
}
#OriginName sub {
  font-size: 14px;
}
.KeywordOnmap:nth-child(2) {
  top: 380px;
  left: 460px;
}
.KeywordOnmap:nth-child(3) {
  top: 460px;
  left: 450px;
}
.KeywordOnmap:nth-child(4) {
  top: 513px;
  left: 420px;
}
.KeywordOnmap:nth-child(5) {
  top: 630px;
  left: 420px;
}
.KeywordOnmap:nth-child(6) {
  top: 582px;
  left: 360px;
}
.KeywordOnmap:nth-child(7) {
  top: 370px;
  left: 750px;
}
.KeywordOnmap:nth-child(8) {
  top: 475px;
  left: 220px;
}
.KeywordOnmap:nth-child(9) {
  top: 580px;
  left: 480px;
}
.KeywordOnmap:nth-child(10) {
  top: 400px;
  left: 600px;
}
.KeywordOnmap:nth-child(11) {
  top: 310px;
  left: 670px;
}
.KeywordOnmap:nth-child(12) {
  top: 350px;
  left: 285px;
}
.KeywordOnmap:nth-child(13) {
  top: 474px;
  left: 540px;
}

/*Responsive */
.static .onlyMobile {display: none;}

@media only screen and (max-width: 1046px) {
  .static.container { transform: none; }
  .Sitemap .nav-wrapper > ul > li {
    width: 30%;
    min-width: 160px;
    height: 370px;
  }
  .Sitemap .subListUl-m li {
    line-height: 2;
  }

}
@media only screen and (max-width: 768px) {
    .static .onlyMobile {display: block;}
    .static .onlyPC {display: none;}
    .static .MenuLeft, .static .MenuRight {width: 100%;}
    .static .Line {display:none;}
    #Milestones .Line { display: none;}
    #Milestones .List {width:100%; margin: 10px 1px 10px 0!important;}
    #Milestones .List:nth-child(2n-1) div {text-align: left;}
    #Milestones .List .Listul, #Milestones .List:nth-child(2n-1) .Listul {float: none; margin: 0 auto; font-size: 14px;}
    #Milestones .List:nth-child(2n-1) .Date { float: left; } /*06.23 D*/
    #Milestones .HistoryTit {max-width: 81%;text-align: justify;}
    .static .Keyword { width: 23%; margin:1%;}
    .static .LeftBox {width: 100%; margin-top: 1em;}
    .static .RightBox {width:100%; height: 45vh; overflow:hidden;}
    .static .SubWrapper img {width:100%}
    #OranizationChart .CharWrapper div::before {width:220px;}
    #OranizationChart .CharWrapper::after {height: 668px;}
    #OriginName .MapImg {width:100%;}
    .static .SubWrapper {padding: 0}
    .static .SubWrapper li {margin-left: 1.5em; text-align: justify;}
    #SiteData .ant-col {width: 33.3%; min-width: 160px; }
    #OriginName {text-align: center;}
    #OriginName div .ant-btn:first-child {display: block; margin: 1em auto .25em;}
    #OriginName div .ant-btn:first-child::after {opacity: 0;}
    #Inquiry .title {max-width: 100%; text-align: center;}
    #Inquiry .title + p {max-width: 100%; text-align: justify;}
    #Inquiry .ant-form-item-label { text-align: left; max-width: fit-content; margin-right: 1em;}
    #Inquiry .textWrapper {width: 100%; flex-direction: column;}
    #Inquiry .ant-form-item {width: 100%; margin: 0 0 1.25em 0;}
    #Archive .ant-btn,#Archive .ant-btn:active, #Archive .ant-btn:hover, #Archive .ant-btn:focus {width: 100%; height: 50px;}
    #Archive .ant-btn {margin: 1% 0;}
    #Support .Menu {margin: 1em 0;}
    #Support .MenuRight { border-bottom: 1px solid #ffd560; line-height: 2;}
    #Greetings .Maintitle {background-position-x: 60%; background-size: 130%;}
    .static.titleLayout .Maintitle::after {width: 100.5%; height: 180px; min-width: 100%;}
    .static.titleLayout .contents img {transform: translateX(-22%);}
    .static.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {right: -10px;}
    .static .ant-form .ant-form-item.seletinput .ant-form-item-control {width: 168px; flex: none;}
    .static.Maintitle h2 {font-size: 28px}
    }

@media only screen and (max-width: 480px) {
    #OranizationChart .CharWrapper div::before {display: none;}
    #OranizationChart .CharWrapper::after { height: 98%; margin-top: -10px;} /*dorohty-3*/
    #OranizationChart .CharWrapper:last-child div  { border: 1px solid #ddd; width: min-content; margin: 0 auto;}
    #OranizationChart .CharWrapper:last-child .Chartdiv {margin-bottom: 3em;}
    #OriginName .ant-btn {margin: 8px;}
    .static .Date {margin-top: 3px;}
    #SiteData .ant-col {width: 50%;}
    #maps .LeftBox > div {text-align: center;}
    #maps a.Icon {margin: 10px 2% 50px}
    #map {max-height: 50vh!important;}
    #Business .contents div:first-child, #Business .contents div:nth-child(2){ width: 100%; }
    #Business .Maintitle {font-size: 1em;} #Business .Maintitle p {font-size: .85em;}
    #Business ul {padding: 0;}
    #Business .contents img {transform: translateX(0); width: 100%;}
    #Archive .Subtitle { display:block;}
    #Archive .Subtitle + span {margin:0 0 16px 24px; font-size: .9em;}
    #OriginName.titleLayout .ContentTitle {font-size: 1.15em;}
    #Greetings .Maintitle {min-height: 320px; }
    #Greetings .Maintitle h2 {font-size: 1.5em;}
    #Greetings .Maintitle h3 {font-size: .9em;}
    #Greetings .Maintitle p {display: none;}
    .static .ant-form-item {width: 100%;}
    #OranizationChart .Chart::after {display: none;}
    .Sitemap .nav-wrapper > ul { float: right; position: relative; left: -50%; width: min-content }
    .Sitemap .nav-wrapper > ul > li { position: relative;  left: 50%; min-width: 300px; height: auto;}
    #Executives .ant-table-tbody > tr > td {padding: 16px 0;}
}


/* .boardLayout,  */
/*content layout 영역*/
.ant-layout.ant-layout-has-sider {padding: 0 15px;}
.ant-layout-sider {margin-right: 15px;}

/* dorothy - main css*/
/* slide */
.poster .arrowbtWrap span {letter-spacing: 5px; margin: 0px 2px 0 8px!important;}
/* tab */
.card-container .ant-tabs .site-card-wrapper .card-text {
  overflow: hidden;
  height: 4.6em;}
@media only screen and (max-width: 1024px) {
  .iconList {max-width: 100%;}
}

.logcontainer .ant-tabs-nav .ant-tabs-tab { width: 100%;}
/* terms 5.18 dorothy */
.cd-faq, .ntemp {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px;
}
