.Main {
    margin: 0 auto;
    max-width: 100vw;
    overflow: hidden;
    width: 100%;
}

.iconList {
    /* padding:70px 0; */
    margin: 0 auto;
}

.iconList .ant-col {
    width: 100%;
    height:100%;
    margin: 0 12px;
}
.iconList .ant-col a{
    width: 100%;
    display: inline-block;
    overflow: hidden;
    max-width: 120px;
}

.iconUl {
    margin: 0;
    padding: 0;
}
.iconUl img{
    max-width:100%;
}
.iconUl li {
    color: #4F4F4F;
    font-size: 1.05rem; 
    text-shadow: 0 0 black;
}

.iconUl li:last-child{
    margin-top: 20px;
}


/* TabView */
.ant-tabs {
    width: 100%;
    margin: 0 auto;
}
.card-container {
    width: 100%;
}
.card-container p {
    margin: 0;
  }
.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 1.1em;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000;
    font-weight: 400;
}  
.card-container > .ant-tabs-card .ant-tabs-content {
    height: auto;
    margin-top: -17px;
    border: 1px solid #F2F2F2;
    position: relative;
  }
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
  }

.card-wrap{
    padding:10px 20px;
    margin:0;
}
.card-tag {
 text-align:left;
 margin-bottom: 10px;
}
.ant-tag {
    border-radius: 15px !important;
}
.card-title{
    text-align: left;
    font-weight: 600;
    font-size:1.2rem;
    height: 64px;
    overflow: hidden;
}
.card-text{
    text-align: left;
    margin-top: 20px;
    font-weight:400;
    font-size:0.8rem;
    line-height:0.95rem;
    color: #828282;
}
.small-text{
    text-align: right;
}
.small-text span,
.small-text a {
    font-size:0.7rem;
    line-height:0.9rem;
    color: #828282 !important;
}

.iconListTitle{
    text-align: left;
    max-width: 200px;
    padding: 0;
}

.listText{
    height: 1.85rem;
    margin: 0;
    padding-top: 10px;
    font-size: 1.65rem;
    font-weight: 100;
    vertical-align: top;
    letter-spacing: -1px;
}

.textBold{
    height: 1.85rem;
    margin: 0;
    padding-top: 10px;
    font-size: 1.65rem;
    vertical-align: top;
    font-weight: bold;
}

.listMore {
    height: 1rem;
    width: 80%;
    margin: 25px 0;
    font-size: 0.9rem;
    /* font-weight: 100; */
    vertical-align: top;
    text-align: right;
    color: #828282;
}

@media (max-width: 1440px){
   
}


@media (max-width:1024px){ 
    .listText, .textBold{
        font-size: 1.45rem;
    }
    .iconList {
        padding: 70px 0;
        max-width: 80%;
        margin: 0 auto;
    }
    .listMore {
        font-size: 0.9rem;
        font-weight: 100;
        vertical-align: top;
        text-align: right;
        color: #828282;
    }
}

/* dorothy 0422 - */
body {width: 100vw;} 
.programSlide {
    height: 100%;
    max-height: 400px;
    min-width: 300px;
}
.programSlide > div { max-height: 400px;}
.boardTabMain > div {
    padding: 4% 0;
    padding-right: 15px;
}
.ant-layout-content, .ant-row { 
    position: relative; 
    background: transparent;}
.Main::before {
    content: '';
    display: block;
    width: 100vw;
    height: 30%;
    min-height: 500px;
    background: #CCE3DF20;
    position: absolute;
    bottom: 0;
}
.sbcBoard::before {
    content: '';
    display: block;
    width: 1000vw;
    height: 62%;
    position: absolute;
    bottom: 18%;
    left: -999px;
    background: #ffd560;
}
.board-card-wrapper {
    font-size: 12px;
}
.board-card-wrapper .sbcboradTitle {
    font-size: 5.2em;
    font-weight: 900;
    line-height: 1;
    color: #ffd560;
    text-align: left;
    margin: 6% 0 0;
}
.board-card-wrapper .sbcboradTitle::after {
    content: "SEONGBUK CULTURE";
    font-size: .5em;
    font-style: normal;
    font-weight: 800;
    color: #FFFFFF80;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;

}
.board-card-wrapper .sbcboradTitle div:last-child {
    color: #fff;
    -webkit-text-stroke: 1px #ffd560;
}
.board-card-wrapper .ant-col {
    box-sizing: border-box;
    padding-left: 15px;
    height: fit-content;
    margin: 0 auto;
}
.board-card-wrapper .ant-card-body {
    font-size: 1.7em;
    font-weight: 600;
    color: #5A5A5A;
}
.board-card-wrapper .ant-card-body span {
    font-size: .5em;
    display: block;
    text-align: right;
    color: #828282;
}
.sbcBoard .board-card-wrapper .ant-card { margin-bottom: 15px;}

.gallerySlide .slick-dots {display: none!important;}
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {color: #222;}

/* .gallerySlide 5.13 dorothy */
.gallerySlide .ant-card-body {
    min-height: 122px;    
}
.gallerySlide .ant-card-body .gallery-card-title span{
    font-size:1.25rem;
    font-weight: 700;
    color:#5A5A5A;
    display: inline-block; 
    width: 102%; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
    margin-bottom: .5em;
}
.gallerySlide .slick-slide .ant-card img {
    height: 250px;
}
/* .boardTabMain 5.13 dorothy */
.board-list-more { 
    text-align: right;
    position: absolute;
    right:10px;
    top: 10px;
    z-index: 10;
    display: block; 
    color: #BDBDBD;
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0 0 0 1em;
}
.board-list-more span { vertical-align: middle;}
.boardTabMain {padding-right: 1em;}

/* Responsive */
.onlyMobile {display: none;}
@media (max-width:1280px){ 
    .sbcBoard::before { height: 60%;}
    .board-card-wrapper .sbcboradTitle {
        font-size: 4em;
        color: rgb(251 182 66);;
    }
    .board-card-wrapper .sbcboradTitle::after {
        -webkit-text-stroke: 0;
    }
    .programSlide .slick-slider .slick-track, .programSlide .slick-slider .slick-list {
        height: 300px;
        overflow: hidden;
    }
    .board-card-wrapper .sbcboradTitle {margin: 1% 0 0;}
    .boardTabMain .card-wrap {
        padding: 0;
    }

}
@media (max-width:990px){
    .boardTabMain .card-title {
        height: 1.6em;
    }
    .boardTabMain .small-text, .boardTabMain .ant-card-body {
        margin: 0; padding: 0;
    }
    .boardTabMain > div { padding: 0; margin-bottom: 5em;}
    .programSlide { margin-top: 40px;}
    .banerMain > .ant-col a { text-align: center!important;}
    .sbcBoard::before { height: 70%;}
    .programMain .onlyPc {display: none;}
}
@media (max-width:786px){ 
    .onlyPc {display: none;}
    .onlyMobile {display: block;}
    .sm-view-padding { padding: 15px; box-sizing: border-box;}
    .board-card-wrapper {
        font-size: 10px;
    }
    .board-card-wrapper .ant-card-body {
        font-size: 1.25em;
    }
    .board-card-wrapper .sbcboradTitle {
        margin: 15px;
        
    }
    .iconList .ant-col {margin: 0 4px;}
    .sbcBoard::before {
        height: 72%;
        bottom: 10%;
    }
    .boardTabMain { min-width: 94vw;}
    .gallerySlide .ant-card-body .gallery-card-title span {
        white-space: normal; 
        line-height: 1.2; 
        height: 2.3em; 
        word-wrap: break-word; 
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    /* .programSlide 5.13 dorothy */
    .programSlide {min-width: 98%!important;}
    .programSlide .slick-track .slick-slide > div > div {
        width: 95%!important;
    }
    .programSlide .slick-slider .slick-track, .programSlide .slick-slider .slick-list {
        height: min-content;
        padding-bottom: 1em;
    }
}
@media (max-width:575px){ 
    .sbcBoard .board-card-wrapper .ant-col {padding: 0; margin: 0 auto; }
}
@media (max-width:480px){ 
    .listMore { max-width: 85px;}
    .boardTabMain { min-width: 100%; padding: 0;}
    .boardTabMain .site-card-wrapper .card-wrap {padding:0;}
    .card-title { height: 3em;}
    .gallerySlide .iconList { padding: 0;}
    .sbcBoard::before {
        height: 90%;
        bottom: 4%;
    }
    .gallerySlide .slick-slide .ant-card { margin-left: 0!important; }
    /* .PosterSlide 5.13 dorothy */
    .programSlide .slick-slider .slick-track, .programSlide .slick-slider .slick-list {
        height: 300px;
    }
    .gallerySlide .slick-slide {
        padding-left: 4px;
    }
    .gallerySlide .ant-card-body .gallery-card-title span{
        font-size: 1em;
    }
    .gallerySlide .ant-card-body {
        height: 80px;
        min-height: auto;
    }
    .gallerySlide .slick-slide .ant-card img {
        height: 160px;
    }
    .gallerySlide .slick-list, .gallerySlide .slick-slider {
        width: 180%;
    }
    .boardTabMain .ant-tabs-nav-operations {display: none;}
}